<div id="search-field-container">
  <mat-form-field id="search-field">
    <mat-label>Kutya név</mat-label>
    <input matInput [formControl]="dogNameFilter" placeholder="Kutya neve" />
  </mat-form-field>

  <mat-form-field id="search-field">
    <mat-label>Munka típus</mat-label>
    <mat-select [formControl]="assistanceTypeFilter">
      <mat-option [value]=""> - </mat-option>
      <mat-option *ngFor="let type of assistanceTypes" [value]="type.key">
        {{ type.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="search-field">
    <mat-label>Mindenben keres</mat-label>
    <input matInput [formControl]="compositeSearchFilter" placeholder="Összetett kereső minden mezőben" />
  </mat-form-field>

  <button id="search-delete-button" mat-button [disabled]="!hasAnySearchFieldsSet()" (click)="clearSearchFields()">Mindent mutat</button>
</div>

<table mat-table [dataSource]="dogList" matSort>
  <ng-container matColumnDef="index">
    <th mat-header-cell *matHeaderCellDef>#</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <span class="table-index">{{ i + 1 }}.</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Kutya neve</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="birthDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Születési idő</th>
    <td mat-cell *matCellDef="let element">{{ element.birthDate | date }}</td>
  </ng-container>

  <ng-container matColumnDef="assistanceTypes">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Munka típus</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.assistanceTypes?.length == 1">{{ getAllAssistanceMultilineName(element) }}</span>
      <ng-container *ngIf="element.assistanceTypes?.length > 1">
        <p [matTooltip]="getAllAssistanceMultilineName(element)" max-width="500" matTooltipClass="multiline">
          {{ element.assistanceTypes?.length }} munkatípus
        </p>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="owner">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gazda</th>
    <td mat-cell *matCellDef="let element">{{ element.owner?.name }}</td>
  </ng-container>

  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef>Gazda tel.</th>
    <td mat-cell *matCellDef="let element">{{ element.owner?.phone || '-' }}</td>
  </ng-container>

  <ng-container matColumnDef="trainer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Kiképző</th>
    <td mat-cell *matCellDef="let element">{{ element.trainer?.name }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        mat-icon-button
        [routerLink]="['/dog', 'training', { dogId: element.docId }]"
        queryParamsHandling="preserve"
        matTooltip="Képzési napló"
      >
        <mat-icon>assignment</mat-icon>
      </button>
      <button
        mat-icon-button
        [routerLink]="['/dog', 'documents', { dogId: element.docId, dogName: element.name }]"
        matTooltip="Dokumentumok/iratok"
        queryParamsHandling="preserve"
      >
        <mat-icon>folder</mat-icon>
      </button>
      <button
        mat-icon-button
        [routerLink]="['/dog', 'provision', { dogId: element.docId, dogName: element.name }]"
        queryParamsHandling="preserve"
        matTooltip="Etetési napló"
      >
        <mat-icon>fastfood</mat-icon>
      </button>
      <button mat-icon-button [routerLink]="['/dog', element.docId, 'edit']" matTooltip="Szerkesztés" queryParamsHandling="preserve">
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        [routerLink]="['/dog', element.docId, 'idcard']"
        matTooltip="Igazolvány készítő"
        queryParamsHandling="preserve"
      >
        <mat-icon>recent_actors</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'student-dog-row': isDogStudent(row) }"></tr>
</table>
